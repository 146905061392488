import React from 'react';
import './Courses.css';
import { Card } from 'react-bootstrap';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';

const courses = [
    {
        id: 1,
        img: "https://images.unsplash.com/photo-1658235081452-c2ded30b8d9f?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJhY2hlbG9yfGVufDB8fDB8fHww",
        title: 'Profile Based University Matching',
        description: 'Personalized profile-based guidance to match you with the best universities and programs that align with your goals.'
    },
    {
        id: 2,
        img: 'https://plus.unsplash.com/premium_photo-1663050763436-818382a24bb8?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGxhbmd1YWdlJTIwc2Nob29sfGVufDB8fDB8fHww',
        title: 'Language Proficiency Training',
        description: 'Specialized training for IELTS, TOEFL, PTE, and German language levels A1 and A2 to ensure you meet language requirements.'
    },
    {
        id: 3,
        img: 'https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8d3JpdGluZ3xlbnwwfHwwfHx8MA%3D%3D',
        title: 'SOP Mastery',
        description: 'Professional support to craft a compelling and authentic Statement of Purpose (SOP), increasing your chances of acceptance.'
    },
    {
        id: 4,
        img: 'https://plus.unsplash.com/premium_photo-1682284548131-58cb47f6ab2b?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2Nob2xhcnNoaXB8ZW58MHx8MHx8fDA%3D',
        title: 'Scholarship & Sponsorship Guidance',
        description: 'Discover lucrative scholarships and sponsorship opportunities from our vast network of partnered institutions.'
    },
    {
        id: 5,
        img: 'https://images.unsplash.com/photo-1581553673739-c4906b5d0de8?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Visa Processing',
        description: 'With our expert visa counselors, we guarantee a hassle-free visa processing experience, ensuring all your documentation is in order.'
    },
    {
        id: 6,
        img: 'https://images.unsplash.com/photo-1536698658763-878a02695d1c?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29uJTIwd2l0aCUyMHN1aXRjYXNlfGVufDB8fDB8fHww',
        title: 'Pre-Departure Facilities',
        description: 'We help you fully prepare for your journey abroad, from visa and accommodation to travel and cultural guidance.'
    }
];

function Courses() {
  return (
    <div className='courses-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Our Services</h1>
                <h3 className='text-center fw-semibold' style={{ color: '#BE1E2D' }}>
                Your One-Stop Solution To Study Abroad
</h3>
                <p className='text-center w-75 mb-5'>Kawaii International Education Center is your trusted partner for achieving academic success abroad. Whether you're dreaming of studying in Canada, Germany, Australia, Malaysia, or South Korea, we offer a comprehensive, one-stop solution to help you fulfill your ambitions.</p>
            </div>
        </header>

        <div className='container py-5'>
            <div className='row g-4'>
                {courses.map((course) => (
                    <div key={course.id} className='col-lg-6'>
                        <Card className='text-white shadow scale-hover-effect'>
                            <Card.Img src={course.img} />
                            <Card.ImgOverlay className='d-flex flex-column align-items-center justify-content-center p-md-5'>
                                <Card.Title className='fs-1 text-danger'>{course.title}</Card.Title>
                                <Card.Text className='text-center'>{course.description}</Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                ))}
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <FaqAccordion />
        </div>
    </div>
  )
}

export default Courses;