import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ChooseSection() {
  return (
    <div>
        <div className="container">
            <h2 className='text-center mb-5'> Your One Stop Solution To Study Abroad</h2>
            <p className='text-center mb-5'> Kawaii International Education Center is your trusted partner for achieving academic success abroad. Whether you're dreaming of studying in Canada, Germany, Australia, Malaysia, or South Korea, we offer a comprehensive, one-stop solution to help you fulfill your ambitions.</p>
            <div className='row g-4'>
                <div className='col-lg-4'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <img src="https://i.ibb.co/9rBm7M0/school-8486134.png" alt="Uni Icon" width="48" height="48" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            Profile Based University Matching
                            </Card.Title>
                            <Card.Text className='text-center'>
                            Personalized profile-based guidance to match you with the best universities and programs that align with your goals.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-4'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <img src="https://i.ibb.co.com/34y9mym/foreign-language-9145738.png" alt="Language Icon" width="48" height="48" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            Language Proficiency Training
                            </Card.Title>
                            <Card.Text className='text-center'>
                            Specialized training for IELTS, TOEFL, PTE, and German language levels A1 and A2 to ensure you meet language requirements.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-4'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <img src="https://i.ibb.co.com/gtm4WM4/sop-5359759.png" alt="Sop Icon" width="48" height="48" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            SOP Mastery
                            </Card.Title>
                            <Card.Text className='text-center'>
                            Professional support to craft a compelling and authentic Statement of Purpose (SOP), increasing your chances of acceptance.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className='row g-4'>
                <div className='col-lg-4'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <img src="https://i.ibb.co.com/dGb4ddC/library-13803635.png" alt="Sop Icon" width="48" height="48" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            Scholarship & Sponsorship Guidance
                            </Card.Title>
                            <Card.Text className='text-center'>
                            Discover lucrative scholarships and sponsorship opportunities from our vast network of partnered institutions.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-4'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <img src="https://i.ibb.co.com/ZxfywNf/visa-10168028.png" alt="Sop Icon" width="48" height="48" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            Visa Processing
                            </Card.Title>
                            <Card.Text className='text-center'>
                            With our expert visa counselors, we guarantee a hassle-free visa processing experience, ensuring all your documentation is in order.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-4'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <img src="https://i.ibb.co.com/44FR3G2/calendar-16557125.png" alt="Sop Icon" width="48" height="48" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            Pre-Departure Facilities
                            </Card.Title>
                            <Card.Text className='text-center'>
                            We help you fully prepare for your journey abroad, from visa and accommodation to travel and cultural guidance.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center">
    <Link to="/courses">
        <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Get Start Now</button>
    </Link>
</div>
    </div>
  )
}

export default ChooseSection;